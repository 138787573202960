import React, { useState, useEffect }from 'react';
import './ModulesAdmin.css';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import Select from 'react-select';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";


function ModuleModal({ module, onClose, onSave, onDelete, isSaving, userRole }) {
  const [pageName, setPageName] = useState(module.pageName);
  const [pageURL, setPageURL] = useState(module.pageURL);
  const [status, setStatus] = useState(module.isActive ? 'active' : 'inactive');
  const [icon, setIcon] = useState(module.pageIconUrl);
  const [description, setDescription] = useState(module.description);
  const [isVisibleForAll, setIsVisibleForAll] = useState(module.isVisibleForAll);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [selectedSubsidiaries, setSelectedSubsidiaries] = useState(
    module.subsidiaries
      ? module.subsidiaries.map((sub) => ({ label: sub, value: sub }))
      : []
  );
  const [isUploading, setIsUploading] = useState(false); 

  useEffect(() => {
    const fetchSubsidiaries = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'subsidiary'));
        const subsidiariesList = querySnapshot.docs.map((doc) => doc.data().name);
        setSubsidiaries(subsidiariesList);
      } catch (error) {
      }
    };
    fetchSubsidiaries();
  }, []);

  // Função de upload de arquivo
  const handleIconUpload = async (file) => {
    if (!file) {
      return;
    }
  
    // Verifica se o arquivo é uma imagem (opcional, mas recomendado)
    if (!file.type.startsWith("image/")) {
      return;
    }
  
    // Exemplo: limite de 5MB
    const maxSize = 5 * 1024 * 1024; 
    if (file.size > maxSize) {
      return;
    }
  
    try {
      setIsUploading(true); // Inicia o estado de carregamento
  
      const storage = getStorage(); // Obtém o Storage do Firebase
      const storageRef = ref(storage, `modulesIcons/${file.name}`); // Cria referência para o arquivo
  
      // Envia o arquivo para o Firebase Storage
      await uploadBytes(storageRef, file);
  
      // Obtém a URL pública do arquivo
      const fileURL = await getDownloadURL(storageRef);
  
      setIcon(fileURL);
    } catch (error) {

    } finally {
      setIsUploading(false); // Finaliza o estado de carregamento
    }
  };

  const handleSave = () => {
    onSave({
      ...module,
      pageName,
      pageURL,
      isActive: status === 'active',
      icon, // Salva a URL do ícone
      description,
      isVisibleForAll,
      subsidiaries: selectedSubsidiaries.map((option) => option.value),
    });
  };

  const handleSubsidiaryChange = (selectedOptions) => {
    setSelectedSubsidiaries(selectedOptions);
  };

  return (
    <div className="modal">
      <div className="modal-content-modules">
        <h2>Edit Module</h2>
        <label>
          <p>Name:</p>
          <input
            className="input-modules"
            type="text"
            value={pageName}
            onChange={(e) => setPageName(e.target.value)}
          />
        </label>
        <label>
          <p>Description:</p>
          <input
            className="input-modules"
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </label>
        <label>
          <p>Link:</p>
          <input
            className="input-modules"
            type="text"
            value={pageURL}
            onChange={(e) => setPageURL(e.target.value)}
          />
        </label>
        <label>
          <p>Icon:</p>
          <input
            className="input-modules"
            type="file"
            accept="image/*"
            onChange={(e) => handleIconUpload(e.target.files[0])} // Sends the selected file
            disabled={isUploading} // Disables input during ongoing upload
          />
        </label>
        <label>
          <p>Status:</p>
          <select
            className="status-dropdown"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </label>
        {userRole === 'admin_global' && (
          <>
            <label>
              <p>Subsidiaries:</p>
              <Select
                isMulti
                options={subsidiaries.map((sub) => ({ label: sub, value: sub }))}
                value={selectedSubsidiaries}
                onChange={handleSubsidiaryChange}
                className="subsidiary-select-dropdown"
                placeholder="Choose subsidiaries..."
              />
            </label>
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={isVisibleForAll}
                onChange={(e) => setIsVisibleForAll(e.target.checked)}
              />
              <span>Visible for all users</span>
            </label>
          </>
        )}
        <div className="modal-actions-modules">
          <button onClick={onClose}>Cancel</button>
          {userRole === 'admin_global' && (
            <button className="deleteModuleButton" onClick={() => onDelete(module)}>
              Delete Module
            </button>
          )}
          <button onClick={handleSave} disabled={isUploading}>
            Save
          </button>
        </div>
        {isSaving && (
          <div className="loading-overlay">
            <div className="spinner"></div>
            <p>Saving changes, please wait...</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ModuleModal;