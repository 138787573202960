import React from 'react';
import './SubsidiarySelectorModal.css'; // Adicione estilos personalizados, se necessário

function SubsidiarySelectorModal({ subsidiaries, onSelect }) {
  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Select a Subsidiary</h2>
        <ul className="subsidiary-list">
          {subsidiaries.map((subsidiary, index) => (
            <li key={index} className="subsidiary-item">
              <button
                onClick={() => onSelect(subsidiary)}
                className="subsidiary-button"
              >
                {subsidiary}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default SubsidiarySelectorModal;
