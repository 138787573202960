// Sidebar.js
import React from 'react';
import './Sidebar.css';
import { FaUsers, FaCogs, FaUserShield } from 'react-icons/fa';

function Sidebar({ userRole, selectedSection, setSelectedSection, navigate }) {
  return (
    <div className="sidebar">
      
        <button
          className={`menu-button ${selectedSection === 'users' ? 'active' : ''}`}
          onClick={() => setSelectedSection('users')}
        >
          <div className='menu-button-content'>
          <div className="icon-circle">
            <FaUsers className="menu-icon" />
            <div className="circle"></div>
          </div>
          <div className="sidebar-text">
            <h3>Module Administration</h3>
            <p>Manage and control user access to modules with ease.</p>
          </div>
          </div>
        </button>
      


        {userRole === 'admin_global' && (
          <button
          className={`menu-button ${selectedSection === 'modules' ? 'active' : ''}`}
          onClick={() => setSelectedSection('modules')}
        >
          <div className='menu-button-content'>
          <div className="icon-circle">
            <FaCogs className="menu-icon" />
            <div className="circle"></div>
          </div>
          <div className="sidebar-text">
            <h3>Modules</h3>
            <p>Activate, deactivate or create modules in the hub easily.</p>
          </div>
          </div>
        </button> 
        )}
         
      <button
        className={`menu-button ${selectedSection === 'user_management' ? 'active' : ''}`}
        onClick={() => setSelectedSection('user_management')}
      >
        <div className='menu-button-content'>
        <div className="icon-circle">
          <FaUserShield className="menu-icon" />
          <div className="circle"></div>
        </div>
        <div className="sidebar-text">
          <h3>User Management</h3>
          <p>Register, view and manage users in the hub.</p>
        </div>
        
        </div>
      </button>
      

    </div>
  );
}

export default Sidebar;