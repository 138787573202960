import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { getDocs, collection, updateDoc, doc, setDoc, deleteDoc, getDoc, writeBatch, query, where } from 'firebase/firestore';
import { useMsal } from '@azure/msal-react';
import Sidebar from './SideBar/Sidebar';
import UsersSection from '../AdminPage/User Admin/UsersSection';
import ModulesSection from './Modules Admin/ModulesSection';
import UserManagement from './UserManagement/UserManagement';
import ModuleModal from './Modules Admin/ModuleModal';
import AddModuleModal from './Modules Admin/AddModuleModal';
import './AdminPage.css';
import '../AdminPage/SideBar/Sidebar.css';
import NavBar from '../Navbar';
import coreLogo from '../../assets/core_logo_white.svg';
import { Navigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function AdminPage({ userRole, userInformations }) {
  const [selectedSection, setSelectedSection] = useState('users'); // Inicialize com a seção de usuários
  const [modulesData, setModulesData] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [isAddingModule, setIsAddingModule] = useState(false);
  const [active, setActive] = useState(null); 
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const { accounts } = useMsal();

  const customStyles = {
    navbar: { backgroundColor: '#0067FF' },
    navbarText: { color: 'white' },
    userInitialNavbar: { backgroundColor: '#004981' },
    userEllipse: { backgroundColor: 'white' },
    userRectangle: { backgroundColor: 'white' },
    arrowIcon: { color: '#0067FF' },
    dropdownMenu: { backgroundColor: 'white' },
    dropdownItem: { color: 'black' },
    backgroundRectangleNavbar: { backgroundColor: 'white' },
    dropdownDivider: { backgroundColor: '#a4a4a4' },
  };

  const loadModulesData = async () => {
    const modulesSnapshot = await getDocs(collection(db, 'modules'));
    const modulesList = modulesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setModulesData(modulesList);
  };

  useEffect(() => {
    loadModulesData(); // Carregar dados dos módulos ao montar o componente
  }, []);

  useEffect(() => {
    if (selectedSection === 'modules') {
      loadModulesData();
    }
  }, [selectedSection]);

  useEffect(() => {
    // Mova a lógica de definição de "active" para o useEffect
    if (userInformations && userInformations.isActive !== undefined) {
      setActive(userInformations.isActive);
    }
  }, [userInformations]);


const handleSaveModule = async (updatedModule) => {
  try {
    setIsSaving(true);

    // Definindo a referência do módulo no Firestore
    let moduleRef;

    if (updatedModule.id) {
      // Atualizar um módulo existente

      moduleRef = doc(db, 'modules', updatedModule.id);
      await updateDoc(moduleRef, {
        pageName: updatedModule.pageName || ' ',
        pageURL: updatedModule.pageURL || ' ',
        isActive: updatedModule.isActive ?? true,
        pageIconUrl: updatedModule.icon || ' ',
        description: updatedModule.description || ' ',
        isVisibleForAll: updatedModule.isVisibleForAll || false,
        subsidiaries: updatedModule.subsidiaries || [],
      });
    } else {
      // Criar um novo módulo
      if (!updatedModule.pageName) {
        return;
      }
      moduleRef = doc(db, 'modules', updatedModule.pageName);
      await setDoc(moduleRef, {
        pageName: updatedModule.pageName,
        pageURL: updatedModule.pageURL || ' ',
        isActive: updatedModule.isActive ?? true,
        pageIconUrl: updatedModule.icon || ' ',
        description: updatedModule.description || ' ',
        isVisibleForAll: updatedModule.isVisibleForAll || false,
        subsidiaries: updatedModule.subsidiaries || [],
      });
    }

    // Se o módulo é visível para todos, adiciona o módulo para todos os usuários
    if (updatedModule.isVisibleForAll) {
      const batch = writeBatch(db);

      // Carrega todos os documentos de empresas e usuários
      const enterprisesSnapshot = await getDocs(collection(db, 'regional_enterprises'));
      enterprisesSnapshot.forEach((enterpriseDoc) => {
        const usersMap = enterpriseDoc.data().users;

        // Adiciona o módulo a cada usuário, se ainda não estiver presente
        Object.keys(usersMap).forEach((email) => {
          const user = usersMap[email];
          if (!user.accessibleModules.some((mod) => mod.id === moduleRef.id)) {
            user.accessibleModules.push(moduleRef);
          }
        });

        // Atualiza o batch com as mudanças no Firestore
        batch.update(doc(db, 'regional_enterprises', enterpriseDoc.id), { users: usersMap });
      });

      // Carregar outros usuários no documento `other_users`
      const otherUsersDoc = await getDoc(doc(db, 'other_users', 'users'));
      if (otherUsersDoc.exists()) {
        const otherUsersMap = otherUsersDoc.data().users;

        Object.keys(otherUsersMap).forEach((email) => {
          const user = otherUsersMap[email];
          if (!user.accessibleModules.some((mod) => mod.id === moduleRef.id)) {
            user.accessibleModules.push(moduleRef);
          }
        });

        batch.update(doc(db, 'other_users', 'users'), { users: otherUsersMap });
      }

      // Commit do batch para salvar todas as atualizações de uma vez
      await batch.commit();
    }


    // Processar subsidiárias
    if (updatedModule.subsidiaries && updatedModule.subsidiaries.length > 0) {
      const batch = writeBatch(db);
      const uniqueDomains = new Set();

      for (const subsidiaryName of updatedModule.subsidiaries) {
        try {
          const subsidiaryQuery = query(
            collection(db, 'subsidiary'),
            where('name', '==', subsidiaryName)
          );
          const querySnapshot = await getDocs(subsidiaryQuery);

          if (!querySnapshot.empty) {
            querySnapshot.forEach((subsidiaryDoc) => {
              const subsidiaryData = subsidiaryDoc.data();

              if (subsidiaryData.domains && Array.isArray(subsidiaryData.domains)) {
                for (const domain of subsidiaryData.domains) {
                  uniqueDomains.add(domain);
                }
              } else {
              }
            });
          } else {
          }
        } catch (error) {
        }
      }

      const domains = Array.from(uniqueDomains);

      const updates = domains.map(async (domain) => {
        try {
          const domainRef = doc(db, 'regional_enterprises', domain);
          const enterpriseDoc = await getDoc(domainRef);

          if (!enterpriseDoc.exists()) {
            return;
          }

          const usersMap = enterpriseDoc.data().users || {};

          Object.keys(usersMap).forEach((email) => {
            const user = usersMap[email];
            if (!Array.isArray(user.accessibleModules)) {
              user.accessibleModules = [];
            }
            if (!user.accessibleModules.some((mod) => mod.id === moduleRef.id)) {
              user.accessibleModules.push(moduleRef);
            }
          });

          batch.update(domainRef, { users: usersMap });
        } catch (error) {
        }
      });

      await Promise.all(updates);

      try {
        await batch.commit();
      } catch (error) {
      }
    }


    loadModulesData();
    setSelectedModule(null);
    setIsAddingModule(false);
    } catch (error) {
    } finally {
      setIsSaving(false); // Desativa o indicador de carregamento
    }
};


  const handleDeleteModule = async (module) => {
    setIsSaving(true);
    const result = await Swal.fire({
      title: 'Delete Confirmation',
      text: `Are you sure you want to delete the module "${module.name}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      customClass: {
        popup: 'custom-popup',      // Personaliza o pop-up
        title: 'custom-title',      // Personaliza o título
        confirmButton: 'custom-confirm-button', // Personaliza o botão de confirmação
        cancelButton: 'custom-cancel-button'    // Personaliza o botão de cancelamento
      }
    });
  
    if (!result.isConfirmed) {
      setIsSaving(false);
      return;
    }
    try {
      const enterprisesRef = collection(db, 'regional_enterprises');
      const querySnapshot = await getDocs(enterprisesRef);
  
      const removeModulePromises = [];
  
      querySnapshot.forEach((docSnapshot) => {
        const enterpriseData = docSnapshot.data();
  
        if (enterpriseData.users) {
          const updatedUsers = {};
  
          // Percorre cada usuário para verificar se o módulo está presente e remover
          Object.entries(enterpriseData.users).forEach(([email, userData]) => {
            if (userData.accessibleModules) {
              const hasModule = userData.accessibleModules.some(
                (accessibleModule) => accessibleModule.id === module.id
              );
  
              if (hasModule) {
                updatedUsers[email] = {
                  ...userData,
                  accessibleModules: userData.accessibleModules.filter(
                    (accessibleModule) => accessibleModule.id !== module.id
                  ),
                };
              }
            }
          });
  
          // Se houve modificações, atualiza o documento do Firestore
          if (Object.keys(updatedUsers).length > 0) {
            const enterpriseRef = doc(db, 'regional_enterprises', docSnapshot.id);
            removeModulePromises.push(
              updateDoc(enterpriseRef, {
                users: {
                  ...enterpriseData.users,
                  ...updatedUsers,
                },
              })
            );
          }
        }
      });
  
      // Aguarda todas as atualizações dos usuários
      await Promise.all(removeModulePromises);
  
      // Em seguida, deletamos o módulo do Firestore
      const moduleRef = doc(db, 'modules', module.id);
      await deleteDoc(moduleRef);
  
      // Atualiza a lista de módulos e limpa a seleção
      loadModulesData();
      setSelectedModule(null);
    } catch (error) {
    } finally {
      setIsSaving(false);
    }
  
  };

  
  // Checar se o usuário está ativo, e se não estiver, redirecionar para a página de "Unauthorized"
  if (active === false) {
    return <Navigate to="/unauthorized" />;
  }


  
  return (
    <div className="admin-container">
      <NavBar 
        userInformations={userInformations} 
        customStylesNavbar={customStyles} 
        logoCore={coreLogo} 
        navbarText={'Admin mode | '}
        pageMode={'admin'}
      />
      <div className='admin-main'>
        <Sidebar
          userRole={userRole}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          navigate={navigate}
        />
        <div className="content">
          {selectedSection === 'users' && (
            <UsersSection userRole={userRole} accounts={accounts} modulesData={modulesData} userInformations={userInformations} />
          )}
          {userRole === 'admin_global' && selectedSection === 'modules' && (
            <ModulesSection
              modulesData={modulesData}
              setSelectedModule={setSelectedModule}
              openAddModuleModal={() => setIsAddingModule(true)}
              userRole={userRole}
            />
          )}
          {selectedSection === 'user_management' && (
            <UserManagement userRole={userRole} accounts={accounts} userInformations={userInformations} />
          )}
          {selectedModule && (
            <ModuleModal
              module={selectedModule}
              onClose={() => setSelectedModule(null)}
              onSave={handleSaveModule}
              onDelete={handleDeleteModule}
              isSaving={isSaving}
              userRole={userRole}
            />
          )}
          {isAddingModule && (
            <AddModuleModal
              onClose={() => setIsAddingModule(false)}
              onSave={handleSaveModule}
              isSaving={isSaving}
              userRole={userRole}
            />
          )}
        </div>
      </div>
      <div className='orange-divider-adminpage'></div>
    </div>
  );
}

export default AdminPage;