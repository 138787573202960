import React from 'react';
import Select from 'react-select'; // Importa o react-select
import '../../Modules Admin/ModulesAdmin.css'; // Reutiliza o CSS do ModuleModal

function EditUserModal({ 
  editingUser, 
  editedName, 
  setEditedName, 
  closeEditPopup, 
  saveEditedUser, 
  deleteUser, 
  userRole, 
  setEditingUser, 
  availableSubsidiaries, 
}) {
  if (!editingUser) return null;

  // Formata as subsidiárias para o formato { label, value }
  const subsidiaryOptions = availableSubsidiaries.map((sub) => ({
    label: sub.name,
    value: sub.id,
  }));

  // Handle para quando as subsidiárias mudam
  const handleSubsidiaryChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value); // Obtem IDs selecionados
    setEditingUser((prev) => ({
      ...prev,
      subsidiaries: selectedValues, // Atualiza as subsidiárias no estado
    }));
  };

  return (
    <div className="modal">
      <div className="modal-content-modules">
        <h2>Edit User</h2>

        {/* Campo de edição do nome */}
        <label>
          <p>Name:</p>
          <input
            className="input-modules"
            type="text"
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
          />
        </label>

        <label>
        <p>Email:</p>
        <input
          className="email-readonly"
          type="text"
          value={editingUser.email}
          readOnly
        />
      </label>


        {/* Dropdown para editar o status */}
        <label>
          <p>Status:</p>
          <select
            className="status-dropdown"
            value={editingUser.isActive ? 'active' : 'inactive'}
            onChange={(e) =>
              setEditingUser((prev) => ({
                ...prev,
                isActive: e.target.value === 'active',
              }))
            }
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </label>

        {/* Dropdown para editar o User Role (visível apenas para admin_global) */}
        {userRole === 'admin_global' && (
          <label>
            <p>User Role:</p>
            <select
              className="status-dropdown"
              value={editingUser.role}
              onChange={(e) =>
                setEditingUser((prev) => ({
                  ...prev,
                  role: e.target.value,
                }))
              }
            >
              <option value="admin_global">Admin Global</option>
              <option value="admin_local">Admin Local</option>
              <option value="user">User</option>
            </select>
          </label>
        )}

        {userRole === 'admin_local' && (
          <label>
            <p>User Role:</p>
            <select
              className="status-dropdown"
              value={editingUser.role}
              onChange={(e) =>
                setEditingUser((prev) => ({
                  ...prev,
                  role: e.target.value,
                }))
              }
            >
              <option value="admin_local">Admin Local</option>
              <option value="user">User</option>
            </select>
          </label>
        )}

        {/* Multi-Select para Subsidiárias (visível apenas para admin_global) */}
        {userRole === 'admin_global' && (
          <label>
            <p>Subsidiaries:</p>
            <Select
              isMulti
              options={subsidiaryOptions}
              value={editingUser.subsidiaries?.map((sub) => ({
                label: availableSubsidiaries.find((s) => s.id === sub)?.name,
                value: sub,
              }))}
              onChange={handleSubsidiaryChange}
              className="subsidiary-select-dropdown"
              placeholder="Choose subsidiaries..."
            />
          </label>
        )}

        {/* Botões de ação */}
        <div className="modal-actions-modules">
          <button onClick={closeEditPopup}>Cancel</button>
            <button
              className="deleteModuleButton"
              style={{ backgroundColor: 'red', color: 'white' }}
              onClick={() => deleteUser(editingUser.email, editingUser.domain)}
            >
              Delete User
            </button>
          <button onClick={saveEditedUser}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default EditUserModal;
