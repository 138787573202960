import React, { useEffect, useState, useRef, useContext } from 'react';
import { useMsal } from '@azure/msal-react';
import { db } from '../../firebaseConfig';
import { doc, getDoc, writeBatch, getDocs, collection, query, where, updateDoc	 } from 'firebase/firestore';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import './Home.css'; 
import NavBar from '../Navbar';
import { Navigate } from 'react-router-dom';
import Loading from '../Loading';
import { AuthContext } from '../AuthContext';
import SubsidiarySelectorModal from './SubsidiarySelectorModal ';

function Home() {
  const { accounts } = useMsal();
  const [modulesData, setModulesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showArrows, setShowArrows] = useState(false);
  const carouselRef = useRef(null);
  const [userInformations, setUserInformations] = useState();
  const [active, setActive] = useState(null); 
  const { authorized, userEmailFromLogin } = useContext(AuthContext);
  const [userCreated, setUserCreated] = useState(false);
  const [subsidiarySelection, setSubsidiarySelection] = useState(null); // Subsidiária selecionada
  const [showSubsidiarySelector, setShowSubsidiarySelector] = useState(false);
  const [subsidiaries, setSubsidiaries] = useState([]); // Subsidiárias disponíveis

  const customStyles = {
    navbar: {
      backgroundColor: 'white',
    },
    navbarText: {
      color: 'black',
    },
    userInitialNavbar: {
      backgroundColor: '#004981',
    },
    userEllipse: {
      backgroundColor: '#0067FF',
    },    
    userRectangle: {
      backgroundColor: '#0067FF',
    },
    arrowIcon: {
      color: 'white',
    },
    dropdownMenu: {
      backgroundColor: '#0067FF',
    },
    dropdownItem: {
      color: 'white',
    },
    backgroundRectangleNavbar: {
      backgroundColor: '#0067FF',
    },
    dropdownDivider: {
      backgroundColor: 'white',
    },
    moduleEllipse: {
      backgroundColor: '#0067FF'    
    },
    carouselInner: {
      justifyContent: 'flex-start',
    }
  };


  useEffect(() => {
    const fetchModulesData = async () => {
      console.log('[fetchModulesData] Iniciando busca de dados do usuário e módulos');
      try {
        const email = accounts.length > 0
          ? accounts[0].idTokenClaims?.email || accounts[0]?.username
          : authorized ? userEmailFromLogin : null;
    
        console.log('[fetchModulesData] Email identificado:', email);
        if (!email) {
          console.warn('[fetchModulesData] Email não encontrado, abortando busca');
          setLoading(false);
          return;
        }
    
        const domain = email.split('@')[1];
        console.log('[fetchModulesData] Domínio do email:', domain);
        if (!domain) {
          console.warn('[fetchModulesData] Domínio não encontrado no email');
          setLoading(false);
          return;
        }
    
        const userDomainRef = doc(db, 'regional_enterprises', domain);
        console.log('[fetchModulesData] Referência ao domínio do usuário:', userDomainRef);
        const userDomainDoc = await getDoc(userDomainRef);
    
        let userDoc = null;    
        if (userDomainDoc.exists()) {
          console.log('[fetchModulesData] Documento do domínio encontrado');
          const domainData = userDomainDoc.data();
          const usersMap = domainData.users;
          setSubsidiaries(domainData.subsidiary || []);
          console.log("subsidiaries: ", subsidiaries);

          if (usersMap) {
            userDoc = usersMap[email];
            console.log('[fetchModulesData] Dados do usuário encontrados:', userDoc);
          }
        } else {
          console.warn('[fetchModulesData] Documento do domínio não encontrado');
        }
        
        if (!userDoc) {
          console.log('[fetchModulesData] Usuário não encontrado no domínio, buscando em "other_users"');
          const otherUsersRef = doc(db, 'regional_enterprises', 'other_users');
          const otherUsersDoc = await getDoc(otherUsersRef);
  
          if (otherUsersDoc.exists()) {
            const otherUsersMap = otherUsersDoc.data().users;
            if (otherUsersMap) {
              userDoc = otherUsersMap[email];
              console.log('[fetchModulesData] Usuário encontrado em "other_users":', userDoc);
            }
          }
        }

        if (userDoc) {
          setUserInformations(userDoc);
          console.log('[fetchModulesData] Informações do usuário definidas:', userDoc);
          setActive(userDoc.isActive ?? false);
  
          const modules = userDoc.accessibleModules || [];
          console.log('[fetchModulesData] Referências dos módulos acessíveis:', modules);
          const modulePromises = modules.map(ref => getDoc(ref).then(doc => doc.data()));
          const modulesData = await Promise.all(modulePromises);
  
          setModulesData(modulesData.filter(module => module.isActive));
          console.log('[fetchModulesData] Dados dos módulos carregados:', modulesData);
        } 
        
        if (!userDoc && userDomainDoc.exists()) {
          if (subsidiaries.length > 1 && !subsidiarySelection) {
            console.log('[fetchModulesData] Mais de uma subsidiária encontrada, exibindo seleção');
            setShowSubsidiarySelector(true);
            return; // Aguarda a seleção antes de continuar
          }
        
          console.log('[fetchModulesData] Criando novo usuário e associando módulos visíveis para todos');
          const modulesRef = collection(db, 'modules');
          const visibleQuery = query(modulesRef, where('isVisibleForAll', '==', true));
          const visibleSnapshot = await getDocs(visibleQuery);
          const visibleModules = visibleSnapshot.docs.map(doc => doc.ref);

          const nameComplete = email.split('@')[0];
          const name = nameComplete.split('.').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
          const role = 'user';
          const isActive = true;
        
          const newUser = {
            name,
            email,
            role,
            isActive,
            accessibleModules: visibleModules || [],
            subsidiaries: (subsidiarySelection && subsidiarySelection.length > 0) 
            ? subsidiarySelection // Usa a seleção se disponível
            : subsidiaries, // Caso contrário, usa as subsidiárias originais
          };
            
          const batch = writeBatch(db);
          batch.set(userDomainRef, {
            users: { [email]: newUser },
          }, { merge: true });
        
          await batch.commit();
          console.log('[fetchModulesData] Novo usuário criado:', newUser);
          setUserCreated(true);
        }
        
      } catch (error) {
        console.error('[fetchModulesData] Erro ao buscar dados:', error);
      } finally {
        console.log('[fetchModulesData] subsidiarySelection: ', subsidiarySelection);
        console.log('[fetchModulesData] Finalizado');
        setLoading(false);
      }
    };

    fetchModulesData();
  }, [accounts, authorized, userEmailFromLogin, subsidiaries, subsidiarySelection]);
   


  useEffect(() => {
    const checkArrowsVisibility = () => {
      if (carouselRef.current) {
        const containerWidth = carouselRef.current.clientWidth;
        const scrollWidth = carouselRef.current.scrollWidth;
        setShowArrows(scrollWidth > containerWidth);
      }
    };

    checkArrowsVisibility();
    window.addEventListener('resize', checkArrowsVisibility);

    return () => {
      window.removeEventListener('resize', checkArrowsVisibility);
    };
  }, [modulesData]);

  const handleSubsidiarySelection = async (selectedSubsidiary) => {
    console.log('[handleSubsidiarySelection] Subsidiária selecionada:', selectedSubsidiary);
  
    try {
      const email = accounts.length > 0
        ? accounts[0].idTokenClaims?.email || accounts[0]?.username
        : authorized ? userEmailFromLogin : null;
  
      if (!email) {
        console.error('[handleSubsidiarySelection] Email do usuário não encontrado');
        return;
      }
  
      const domain = email.split('@')[1];
      const userDomainRef = doc(db, 'regional_enterprises', domain);
      const userDomainDoc = await getDoc(userDomainRef);
  
      if (userDomainDoc.exists()) {
        const usersMap = userDomainDoc.data().users;
  
        if (usersMap[email]) {
          usersMap[email] = {
            ...usersMap[email],
            subsidiaries: [selectedSubsidiary], // Salva as subsidiárias
          };
  
          await updateDoc(userDomainRef, { users: usersMap });
          console.log('[handleSubsidiarySelection] Informações do usuário atualizadas com sucesso');
  
          // Atualizando o estado local após a atualização no Firestore
          setSubsidiarySelection([selectedSubsidiary]);
          setShowSubsidiarySelector(false);
        }
      }
    } catch (error) {
      console.error('[handleSubsidiarySelection] Erro ao atualizar informações do usuário:', error);
    }
  };
  
  


  if (showSubsidiarySelector) {
    return (
      <SubsidiarySelectorModal
        subsidiaries={subsidiaries}
        onSelect={handleSubsidiarySelection}
      />
    );
  }
  
  const scrollCarousel = (direction) => {
    if (carouselRef.current) {
      const scrollAmount = 300; // Quantidade de rolagem
      const scrollDistance = direction === 'left' ? -scrollAmount : scrollAmount;
      carouselRef.current.scrollLeft += scrollDistance;
    }
  };
  


  // Função para garantir que o link comece com http:// ou https://
  const ensureProtocol = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };


  if (loading) {
    console.log('[Home] Exibindo carregamento');
    return <Loading />;
  }

  if (userCreated) {
    console.log('[Home] Redirecionando para página de sucesso');
    return <Navigate to="/success" />;
  }

  if (!active) {
    console.warn('[Home] Usuário inativo, redirecionando para página não autorizada');
    return <Navigate to="/unauthorized" />;
  }


  return (

    <div className='mainpage'>
      <NavBar 
        userInformations={userInformations} 
        customStylesNavbar={customStyles} 
        navbarText={'Hello, '} 
        pageMode={'corporate'}
      />

      <div className="main-home">
        <div className='main-container-home'>
          <div>
            <div className='text-container-homepage'>
                        <p className="text1">Have everything at your fingertips: </p>
                        <p className="text2">tools, profiles, projects, and more.</p>
                      </div>
                      
                      {/* Botão de rolagem para a esquerda */}
                      {showArrows && (
                        <button
                          onClick={() => scrollCarousel('left')}
                          style={{ 
                            position: 'absolute', 
                            left: '0', 
                            zIndex: 2, 
                            backgroundColor: 'white', 
                            border: 'none', 
                            cursor: 'pointer',
                            padding: '10px',
                          }}
                        >
                          <FaChevronLeft size={30} color="#5ABEFE" />
                        </button>
                      )}

                  {/* Contêiner do carrossel */}
                  <div className="carouselOuter">
                  {/* Contêiner do carrossel */}
                  <div ref={carouselRef} className="carouselInner" style={customStyles.carouselInner}>
                    {modulesData.length > 0 && (
                      <div className="gridContainer">
                        {modulesData.map((moduleData, index) => {
                          return (
                            <a
                              key={index}
                              href={ensureProtocol(moduleData.pageURL)}
                              target="_blank"
                              rel="noopener noreferrer"
                              className='homepage-card'
                            >
                      <div className="icon-container">
                        <div className="moduleEllipse" style={customStyles.moduleEllipse}>
                          {moduleData.pageIconUrl ? (
                            <span
                            className="module-icon"
                            style={{
                              display: 'inline-block', // Permite que o span contenha o SVG com tamanho ajustável
                              width: '60%', // Ajusta a largura à elipse
                              height: '60%', // Ajusta a altura à elipse
                              background: `url(${moduleData.pageIconUrl}) no-repeat center`,
                              backgroundSize: 'contain', // Ajusta a proporção da imagem
                              color: 'white',
                            }}
                            aria-label="Ícone do módulo" // Acessibilidade
                          ></span>
                          
                          ) : (
                            <span className="icon-style">Icone padrão</span> // Texto ou ícone padrão, caso a URL esteja ausente
                          )}
                        </div>
                      </div>

                  {/* Nome do módulo */}
                  <div className="text-style-card-homepage">{moduleData.pageName}</div>
                  
                  {/* Descrição do módulo */}
                  <div className="description-homepage">{moduleData.description}</div>
                            </a>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  </div>

                      {/* Botão de rolagem para a direita */}
                      {showArrows && (
                        <button
                          onClick={() => scrollCarousel('right')}
                          style={{ 
                            position: 'absolute', 
                            right: '0', 
                            zIndex: 2, 
                            backgroundColor: 'white', 
                            border: 'none', 
                            cursor: 'pointer',
                            padding: '10px',
                          }}
                        >
                          <FaChevronRight size={30} color="#5ABEFE" />
                        </button>
                      )}
          </div>
          
        </div>
      
      <div className='orange-footer-homepage'></div>
      </div>
        </div>
        
  );
  
}

export default Home;
