import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import './navbar.css';
import localCoreLogo from '../../assets/core_logo_blue.svg';
import { FaChevronDown, FaChevronUp, FaBuilding, FaSignOutAlt } from "react-icons/fa";

const NavBar = ({ userInformations, customStylesNavbar = {}, logoCore, navbarText, pageMode }) => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [menuMode, setMenuMode] = useState('User Mode');

  //const userEmail = accounts[0]?.username || '';
  //const userInitial = userEmail.charAt(0).toUpperCase();
  const userInitial = userInformations.name.charAt(0).toUpperCase();


  useEffect(() => {
    if (pageMode === 'admin') {
      setMenuMode('Corporate Mode');
    } else if (pageMode === 'corporate') {
      setMenuMode('Admin Mode');
    }
  }, [pageMode]);

  const handleNavigate = () => {
    if (pageMode === 'corporate') {
      navigate('/admin'); // Navega para /admin se for admin mode
    } else if (pageMode === 'admin') {
      navigate('/'); // Navega para /home se for user mode
    }
  };

  const handleLogout = () => {
    instance.logoutRedirect().catch(e => {
    });
  };
  
  const toggleDropdown = () => {
      setShowDropdown(!showDropdown);
  };


  return (
    <div className='main-container-navbar'>
      <nav className="navbar" style={customStylesNavbar.navbar}>
        {/* Logo */}
        <div className="navbar-logo-container" style={customStylesNavbar.navbarLogoContainer}>
          {logoCore ? (
            <img src={logoCore} alt="Logo" className="navbar-logo" style={customStylesNavbar.navbarLogo}/>
          ) : (
            <img src={localCoreLogo} alt="Logo" className="navbar-logo" style={customStylesNavbar.navbarLogo}/>
          )}
          
        </div>
        <div className='user-section-container' style={customStylesNavbar.container}>
            <div className='user-section' style={customStylesNavbar.section}>
                <span className="navbarText" style={customStylesNavbar.navbarText}>
                    {navbarText} <strong>{userInformations.name}</strong>
                </span>
                <div className="photo-menu" onClick={toggleDropdown} style={customStylesNavbar.photoMenu}>
                    <div className="userInitialNavbar" style={customStylesNavbar.userInitialNavbar}>
                        {userInitial}
                    </div>
                    <span className='arrowIcon' style={customStylesNavbar.arrowIcon}>
                        {showDropdown ? <FaChevronUp /> : <FaChevronDown />}
                    </span>
                    <div className="userEllipse" style={customStylesNavbar.userEllipse}></div>
                    <div className="userRectangle" style={customStylesNavbar.userRectangle}></div>
                    <div className="background-rectangle" style={customStylesNavbar.backgroundRectangle}></div>
                    {showDropdown && (
                        <div className="dropdown-container" style={customStylesNavbar.dropdownContainer}>
                            <div className="background-rectangle-navbar" style={customStylesNavbar.backgroundRectangleNavbar}></div>
                            <ul className='dropdownMenu-navbar' style={customStylesNavbar.dropdownMenu}>
                                {(userInformations.role === 'admin_global' || userInformations.role === 'admin_regional' || userInformations.role === 'admin_local') && (
                                    <div>
                                        <div className="dropdownItemNavbar" style={customStylesNavbar.dropdownItem} onClick={handleNavigate}>
                                        {menuMode} <FaBuilding/>
                                        </div>
                                        <div className='dropdownDivider' style={customStylesNavbar.dropdownDivider}></div>
                                    </div>
                                )}
                                <div className='dropdownItemNavbar' style={customStylesNavbar.dropdownItem} onClick={handleLogout}>
                                    Log out <FaSignOutAlt />
                                </div>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
      </nav>
      <div className="orange-divider"></div>
    </div>

  );
};

export default NavBar;
