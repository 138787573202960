// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';


// Configuração do Firebase para o ambiente de produção
const firebaseConfigPROD = {
  apiKey: "AIzaSyAJRlzQ0ygZtz3FMax7Kai4f73qzv4isJw",
  authDomain: "testehub-2f05c.firebaseapp.com",
  projectId: "testehub-2f05c",
  storageBucket: "testehub-2f05c.firebasestorage.app",
  messagingSenderId: "279901455113",
  appId: "1:279901455113:web:0b65f41fad7a951a2049d8"
};


// Configuração do Firebase para o ambiente de desenvolvimento
const firebaseConfigDEV = {
  apiKey: "AIzaSyCtmKKNiaVGXsCmwoUaWaOd179zLsNs3js",
  authDomain: "hubb-80a63.firebaseapp.com",
  projectId: "hubb-80a63",
  storageBucket: "hubb-80a63.firebasestorage.app",
  messagingSenderId: "732544521883",
  appId: "1:732544521883:web:73d4386f3c67f7af59983e",
  measurementId: "G-9J47XW15TX"
};

// Inicializar o Firebase
const app = initializeApp(firebaseConfigPROD);

// Exportar o Firestore e o Auth
export const db = getFirestore(app);
export const auth = getAuth(app); 
export const storage = getStorage(app);
