import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    // "94419d9e-40f7-4eee-877a-548a2654b6da" - PRODUÇÃO BELGICA
    // "abee6489-9be5-4de6-8860-d68bf08f9158"- TESTEHUB NO BRASIL 
    clientId: "94419d9e-40f7-4eee-877a-548a2654b6da" , 
    authority: "https://login.microsoftonline.com/common", 
    redirectUri: window.location.hostname === 'localhost'
    ? "http://localhost:3000/" 
    : window.location.hostname === 'hubb-80a63.web.app' 
        ? "https://hubb-80a63.web.app/"
        : "https://core.tpf.be/" 
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false, 
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (level === 'error') {
        } else if (level === 'info') {
        } else if (level === 'verbose') {
        } else if (level === 'warning') {
        }
      },
      piiLoggingEnabled: false,
      logLevel: 'info',
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: ["User.Read"], 
};